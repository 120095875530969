import React, { useState, useEffect, useRef } from "react";
import "./WinesDetails.css";
import data from "../../local-json/data.json";

const WinesDetails = () => {
  //get the product id from the url
  const url = window.location.href;
  const id = url.substring(url.lastIndexOf("/") + 1);
  console.log(id);

  //get the product details from the json file
  const product = data[id - 1].item;
  console.log("product details", product);

  //get the product image from the imgs folder
  const img = product.productDetails;
  console.log("image", img);

  const imgMobile = product.productDetailsMobile;
  console.log("imageMobile", imgMobile);

  const lbpPrice = useRef(0);
  const [test, setTest] = useState(1);

  const [rate, setRate] = useState({
    buy_rate: "0",
    sell_rate: "0",
  });

  useEffect(() => {
    fetch("https://rate.onrender.com/api/v1/dollarRate")
      .then((res) => res.json())
      .then((data) => {
        setRate(data);
        handleData(data);
        // console.log(data);
        let dollarPrice = product.productPrice;
        lbpPrice.current = dollarPrice * data["buy_rate"];
        // console.log(lbpPrice);
        setTest(lbpPrice.current);
      });
  }, []);

  function handleData(data) {
    data["buy_rate"] = data["buy_rate"].split(",").join("");
  }

  return (
    <div className="main-container">
      {window.innerWidth > 768 ? (
        <img
          // loading="lazy"
          src={require(`../../assets/landscape/${img}`)}
          alt="DesktopImage"
        />
      ) : (
        <img
          // loading="lazy"
          src={require(`../../assets/mobile/${imgMobile}`)}
          alt="MobileImage"
        />
      )}
    </div>
  );
};

export default WinesDetails;
