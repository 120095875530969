import React, { useState, useEffect } from "react";
import "./Main.css";
import Welcome from "../Welcome/Welcome";
import Home from "../Home/Home";
import About from "../About/About";
import WineList from "../WineList/WineList";
import Events from "../Events/Events";
import ContactUs from "../ContactUs/ContactUs";
import { BsWhatsapp } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
const App = (props) => {
  useEffect(() => {
    if (window.buttonClicked) {
      const element = document.getElementById("wine_list");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const [show, setShow] = useState(true);

  const handleShow = () => {
    setShow(false);
  };

  const [websiteOpenedBefore, setWebsiteOpenedBefore] = useState(false);

  // Update the state variable when the component is mounted
  useEffect(() => {
    setWebsiteOpenedBefore(true);
  }, []); // Use an empty dependencies array to only run this effect once

  const handleClick = () => {
    // Select the section element using the target
    const section = document.getElementById("home");

    // Scroll to the section
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div id="Main">
      {/* Only show the age verification modal if the website has not been opened before */}
      {websiteOpenedBefore ? null : (
        <div
          className="age-verification-container"
          id="age-verification-container"
          style={{ display: show ? "inline-block" : "none" }}
        >
          <div className="age-verification-container_d">
            <div>
              <h1>ARE YOU 18, OR OLDER?</h1>
            </div>
            <p>YOU MUST BE OVER 18 TO USE THIS APP</p>
            <div>
              <button
                id="age_yes"
                className="age-verification-btn"
                onClick={() => handleShow()}
              >
                YES
              </button>
              <button
                className="age-verification-btn"
                onClick={() => window.open("https://www.google.com", "_self")}
              >
                NO
              </button>
            </div>
          </div>
        </div>
      )}
      <Welcome />
      <Home function={props.function} />
      <About />
      <WineList function={props.function} />
      <Events />
      <ContactUs />
      <div className="scroll-up" id="sokohawhaw">
        <div className="scroll-up-icon">
          <a onClick={handleClick}>
            <IoIosArrowUp className="s-u-i-s" />
          </a>
        </div>
        <div className="home-btn">
          <a onClick={handleClick} className="nmnmnm">
            Home
          </a>
        </div>
      </div>

      <div className="socials_container" id="sokonawnaw">
        <div className="socials">
          <div className="socials_icons">
            {" "}
            <p className="social_text">Chat With Us</p>
            <BsWhatsapp
              className="social_icon_s"
              onClick={() =>
                window.open("https://wa.me/+96181939034", "_blank")
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
