import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Wines.css";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import data from "../../local-json/data.json";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { BsWhatsapp } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";

const Wines = (props) => {
  console.log("wine props", props);

  const [productType, setProductType] = useState("");

  useEffect(() => {
    setProductType(window.location.hash.split("/")[2]);
  }, []);

  console.log("productType", productType);
  const filteredWines =
    productType === "SearchResults"
      ? props.filter
      : data.filter((item) => {
          if (productType === "All") {
            return item;
          }
        });

  const showDropdown = () => {
    const dropdownContent = document.getElementById("dropdown-content");
    const arrowDown = document.getElementById("arrow-down");
    const arrowUp = document.getElementById("arrow-up");
    if (dropdownContent.style.display === "flex") {
      dropdownContent.style.display = "none";
      arrowDown.style.display = "block";
      arrowUp.style.display = "none";
    } else {
      dropdownContent.style.display = "flex";
      arrowUp.style.display = "block";
      arrowDown.style.display = "none";
    }
  };

  const [info, setInfo] = useState({
    checkedValues: [],
  });

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const { checkedValues } = info;

    console.log(`${value} is ${checked}`);

    if (checked) {
      setInfo({
        checkedValues: [...checkedValues, value],
      });
    } else {
      setInfo({
        checkedValues: checkedValues.filter((e) => e !== value),
      });
    }
  };

  const filter = () => {
    showDropdown();
    console.log("info: ", info);
    const filteredData = data.filter((item) => {
      return (
        //check if number of checked filters is greater than or equal to 1
        (info.checkedValues.length >= 1 &&
          (info.checkedValues.includes(item.item.productType) ||
            info.checkedValues.includes(item.item.productCountry) ||
            info.checkedValues.includes(item.item.productRegion))) ||
        //check if number of checked filters is 3
        (info.checkedValues.length >= 2 &&
          info.checkedValues.includes(item.item.productType) &&
          info.checkedValues.includes(item.item.productCountry) &&
          info.checkedValues.includes(item.item.productRegion)) ||
        // //check if number of checked filters is 2
        (info.checkedValues.length === 2 &&
          ((info.checkedValues.includes(item.item.productType) &&
            info.checkedValues.includes(item.item.productCountry)) ||
            (info.checkedValues.includes(item.item.productType) &&
              info.checkedValues.includes(item.item.productRegion)) ||
            (info.checkedValues.includes(item.item.productCountry) &&
              info.checkedValues.includes(item.item.productRegion)))) ||
        // //check if number of checked filters is 1
        (info.checkedValues.length === 1 &&
          (info.checkedValues.includes(item.item.productType) ||
            info.checkedValues.includes(item.item.productCountry) ||
            info.checkedValues.includes(item.item.productRegion))) ||
        //check if no filters are checked
        info.checkedValues.length === 0 ||
        //if 1 product type is selected and multple countries and regions are selected
        (info.checkedValues.length > 1 &&
          info.checkedValues.includes(item.item.productType) &&
          (info.checkedValues.includes(item.item.productCountry) ||
            info.checkedValues.includes(item.item.productRegion))) ||
        //if 1 product country is selected and multple types and regions are selected
        (info.checkedValues.length > 1 &&
          info.checkedValues.includes(item.item.productCountry) &&
          (info.checkedValues.includes(item.item.productType) ||
            info.checkedValues.includes(item.item.productRegion))) ||
        //if 1 product region is selected and multple types and countries are selected
        (info.checkedValues.length > 1 &&
          info.checkedValues.includes(item.item.productRegion) &&
          (info.checkedValues.includes(item.item.productType) ||
            info.checkedValues.includes(item.item.productCountry)))
      );
    });
    console.log("length: ", info.checkedValues.length);
    console.log("filteredData: ", filteredData);

    //store in the props cause it's not working
    props.function(filteredData);
  };

  const pTypes = {};

  filteredWines.forEach((item) => {
    if (pTypes[item.item.productType]) {
      pTypes[item.item.productType] += 1;
    } else {
      pTypes[item.item.productType] = 1;
    }
    if (pTypes[item.item.productType] > 1) {
      item.productType = "SearchResults";
    }
  });

  const uniqueTypes = Object.keys(pTypes);

  const handlehome = () => {
    window.buttonClicked = true;
    window.location.href = "/#/";
  };

  useEffect(() => {
    const element = document.getElementById("wine_list");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      filterSearch();
      window.location.href = "/#/wines/SearchResults";
    }
  };

  const filterSearch = () => {
    const filteredData = data.filter((item) => {
      let productName = item.item.productName.toLowerCase();
      let productType = item.item.productType.toLowerCase();
      let productCountry = item.item.productCountry.toLowerCase();
      let productRegion = item.item.productRegion.toLowerCase();
      return (
        productName.includes(searchInput.toLowerCase()) ||
        productType.includes(searchInput.toLowerCase()) ||
        productCountry.includes(searchInput.toLowerCase()) ||
        productRegion.includes(searchInput.toLowerCase())
      );
    });
    console.log("filteredData: ", filteredData);
    props.function(filteredData);
  };

  const [searchInput, setSearchInput] = useState("");

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    console.log("searchInput: ", searchInput);
  };

  return (
    <div className="sec">
      <h1>{props.name}</h1>
      <section id="Wines">
        <div className="wines-container">
          <div className="logo" id="mohamad">
            <div loading="lazy" width={100} height={200} id="mohamadimg"></div>
          </div>
          <div className="back">
            <div className="back-section">
              <a onClick={handlehome}>
                <IoChevronBackCircleSharp className="back-icon" />
              </a>
              <Link onClick={() => (window.buttonClicked = true)} to="/">
                Back to Wine List
              </Link>
            </div>
            <div className="searchNew" onKeyDown={handleKeyDown}>
              <input
                type="text"
                placeholder="Search Product..."
                onFocus={(e) => (e.target.placeholder = "Type")}
                onBlur={(e) => (e.target.placeholder = "Search Product...")}
                value={searchInput}
                onChange={handleChangeSearch}
              />
              <Link to="/wines/SearchResults">
                <button className="search-button" onClick={filterSearch}>
                  <CiSearch className="search-icon" />
                </button>
              </Link>
            </div>
          </div>
          <div className="filters">
            <div className="drop">
              <div className="filterBtn">
                <a to="#" className="filter">
                  Filter
                </a>
              </div>
              <div className="arrows arr">
                <button className="arrow-button" onClick={showDropdown}>
                  <IoIosArrowDown id="arrow-down" />
                  <IoIosArrowUp id="arrow-up" />
                </button>
              </div>
            </div>
            <div className="dropdown-positions1 con">
              <div className="dropdown-container1">
                <div id="dropdown-content">
                  <div className="contents">
                    <div className="left-content1">
                      <div className="left-content-title tttl">
                        <h3>Type</h3>
                      </div>
                      <div className="left-content-items tmtms">
                        <div className="checkbox">
                          <div>
                            <input
                              type="checkbox"
                              id="red"
                              value="Red"
                              onChange={handleChange}
                            />
                            <label for="red">Red</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="white"
                              value="White"
                              onChange={handleChange}
                            />
                            <label for="white">White</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="rose"
                              value="Rose"
                              onChange={handleChange}
                            />
                            <label for="rose">Rosé</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="sparkling"
                              value="Sparkling"
                              onChange={handleChange}
                            />
                            <label for="sparkling">Sparkling</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="middle-content1">
                      <div className="middle-content-title">
                        <h3>Region</h3>
                      </div>
                      <div className="middle-content-items">
                        <div className="checkbox">
                          <div>
                            <input
                              type="checkbox"
                              id="tuscany"
                              value="Tuscany"
                              onChange={handleChange}
                            />
                            <label for="tuscany">Tuscany</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="piedmont"
                              value="Piedmont"
                              onChange={handleChange}
                            />
                            <label for="piedmont">Piedmont</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="veneto"
                              value="Veneto"
                              onChange={handleChange}
                            />
                            <label for="veneto">Veneto</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right-content1">
                      <div className="right-content-title">
                        <h3>Country</h3>
                      </div>
                      <div className="right-content-items1 rhth">
                        <div className="checkbox">
                          <div>
                            <input
                              type="checkbox"
                              id="france"
                              value="France"
                              onChange={handleChange}
                            />
                            <label for="france">France</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="italy"
                              value="Italy"
                              onChange={handleChange}
                            />
                            <label for="italy">Italy</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter wbw">
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/wines/SearchResults"
                    >
                      <button className="filter-button" onClick={filter}>
                        Filter
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="wines-cat">
              <div className="wines-cat-title">
                {productType === "SearchResults" ? (
                  <h3 className="title-search">Search Result</h3>
                ) : (
                  <h3 className="title">{productType} Wine</h3>
                )}
              </div>
            </div>
          </div>
          <div className="wines">
            <div className="wines1">
              {/* //loop over filteredWines and display them each 5 on a row and on mobile 2 on a row*/}
              {filteredWines.map((wine) => {
                return (
                  <>
                    <div className="wine">
                      <Link to={`/winedetails/${wine.id}`}>
                        <div className="wine-img">
                          <img
                            loading="lazy"
                            src={require(`../../images/final-imgs/${wine.item.productImage}`)}
                            alt="wine"
                            className="product-img"
                          />
                        </div>
                      </Link>
                      <div className="wine-desc">
                        <p>{wine.item.productName}</p>
                        <p>{wine.item.productBrand}</p>
                        {/* <p>{wine.item.productPrice}</p> */}
                      </div>
                    </div>
                  </>
                );
              })}
              <div className="wine-fake">
                <div className="wine-img-fake"></div>
                <div className="wine-desc">
                  <p></p>
                  <p></p>
                  <p></p>
                </div>
              </div>
              <div className="wine-fake">
                <div className="wine-img-fake"></div>
                <div className="wine-desc">
                  <p></p>
                  <p></p>
                  <p></p>
                </div>
              </div>
              <div className="wine-fake">
                <div className="wine-img-fake"></div>
                <div className="wine-desc">
                  <p></p>
                  <p></p>
                  <p></p>
                </div>
              </div>
              <div className="wine-fake">
                <div className="wine-img-fake"></div>
                <div className="wine-desc">
                  <p></p>
                  <p></p>
                  <p></p>
                </div>
              </div>
              <div className="wine-fake">
                <div className="wine-img-fake"></div>
                <div className="wine-desc">
                  <p></p>
                  <p></p>
                  <p></p>
                </div>
              </div>
              <div className="wine-fake">
                <div className="wine-img-fake"></div>
                <div className="wine-desc">
                  <p></p>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="socials_container" id="sokonawnaw">
        <div className="socials">
          <div className="socials_icons">
            {" "}
            <p className="social_text">Chat With Us</p>
            <BsWhatsapp
              className="social_icon_s"
              onClick={() =>
                window.open("https://wa.me/+96181939034", "_blank")
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wines;
