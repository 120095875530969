import React from "react";
import "./ContactUs.css";
import { HiPhone } from "react-icons/hi";
import { GoMail } from "react-icons/go";
import { FiInstagram } from "react-icons/fi";
import { CgFacebook } from "react-icons/cg";
import { GiRotaryPhone } from "react-icons/gi";
import MapImage from "../../assets/newmap1.png";

import topLogo from "../../assets/imgs/3.png";

const ContactUs = () => {
  return (
    <section id="contact_us">
      <div className="topLogo">
        <img
          src={topLogo}
          alt="topLogo"
          width={235}
          height={235}
          className=""
        />
      </div>
      {/* <div className="logo_contact_us">
        <img loading="lazy" src={logo} alt="logo" width={700} height={120} />
      </div> */}
      <div className="contact_us_container">
        <div className="contact_us_container_left">
          <div>
            <h1 className="title_c">CONTACT US</h1>
          </div>

          <div className="contact_us_container_left_content up">
            <div className="number_left">
              <article className="contact_us_details">
                <GiRotaryPhone className="contact_us_details-icon" />
                <a href="tel:+961001853544">+9611853544</a>
              </article>
              <article className="contact_us_details">
                <HiPhone className="contact_us_details-icon" />
                <a href="tel:+96181939034">+96181939034</a>
              </article>
              <article className="contact_us_details">
                <GoMail className="contact_us_details-icon" />
                <a href="mailto:info@saluti.me">info@saluti.me</a>
              </article>
              {/* <article className="contact_us_details">
                <FiInstagram className="contact_us_details-icon" />
                <a href="https://www.instagram.com/saluti_trading/">
                  Saluti Trading
                </a>
              </article> */}
              <article className="contact_us_details">
                <CgFacebook className="contact_us_details-icon" />
                <a
                  href="https://www.facebook.com/profile.php?id=100076418265668"
                  target="_blank"
                >
                  Saluti Trading
                </a>
              </article>
            </div>
            {/* <div className="number_right">
              <article className="contact_us_details">
                <FiInstagram className="contact_us_details-icon" />
                <a href="https://www.instagram.com/thewinehaven.shop/">
                  thewinehaven.shop
                </a>
              </article>
              <article className="contact_us_details">
                <CgFacebook className="contact_us_details-icon" />
                <a href="https://www.facebook.com/profile.php?id=100088972295767">
                  Thewinehaven.shop
                </a>
              </article>
            </div> */}
          </div>
          <div>
            <h1 className="title_c title_f">REACH OUT</h1>
          </div>
          <div className="contact_us_container_left_content below">
            <form
              action="https://getform.io/f/4db40aa9-8f5e-4682-801d-4c7a6c5eaae9"
              method="POST"
            >
              <div className="form_inputs_container">
                <input
                  className="form_inputs"
                  type="text"
                  placeholder="First Name"
                  required
                  name="firstNname"
                />
                <input
                  className="form_inputs inputs_right"
                  type="text"
                  placeholder="Last Name"
                  name="lastNname"
                  required
                />
                <input
                  className="form_inputs"
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                />
                <input
                  className="form_inputs inputs_right"
                  type="tel"
                  placeholder="Phone Number"
                  pattern="[0-9]{2}[0-9]{3}[0-9]{3}"
                  required
                />
              </div>
              <textarea
                className="form_text_area"
                rows={2}
                name="message"
                placeholder="Type your message here"
              ></textarea>

              <button type="submit" className="form_submit_button">
                Submit
              </button>
            </form>
          </div>
        </div>
        <div className="contact_us_container_right">
          <div>
            <h1 id="map_title">OUR LOCATION</h1>
          </div>
          <div className="contact_us_right_content">
            <div className="contact_us_map">
              <img
                src={MapImage}
                alt="map"
                onClick={() =>
                  window.open("https://goo.gl/maps/R6v7vJYFtvkxaWHJ6", "_blank")
                }
                id="map_pic"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
