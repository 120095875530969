import React from "react";
import "./EventsImages.css";
import "react-slideshow-image/dist/styles.css";
import ImageSlider, { Slide } from "react-auto-image-slider";
// import img from "../../images/img.jpeg";

const EventsImages = () => {
  return (
    <div className="home_container">
      <ImageSlider effectDelay={500} autoPlayDelay={2000}>
        <Slide className="slider">
          {/* <img loading="lazy" className="slider-image" alt="img1" src={img} /> */}
        </Slide>
        <Slide>
          {/* <img loading="lazy" className="slider-image" alt="img2" src={img} /> */}
        </Slide>
        <Slide>
          {/* <img loading="lazy" className="slider-image" alt="img3" src={img} /> */}
        </Slide>
      </ImageSlider>
    </div>
  );
};

export default EventsImages;
