import React from "react";
import ReactDOM from "react-dom";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import "./fonts/ostrich-regular.ttf";
import {createRoot} from 'react-dom/client';

// ReactDOM.render(<App />, document.getElementById('root'));
createRoot(document.getElementById('root')).render(<App />);
// ReactDOM.render(<App />, document.querySelector("#root"));
