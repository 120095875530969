import React, { useEffect, useState } from "react";
import "./AdminPanel.css";

//create a function to make rate appear
// function change() {}

const AdminPanel = () => {
  const [show, setShow] = useState(false);

  const [useBlackMarketRate, setUseBlackMarketRate] = useState(false);
  const [data, setData] = useState({
    buy_rate: "0",
    sell_rate: "0",
  });

  useEffect(() => {
    fetch("https://rate.onrender.com/api/v1/dollarRate")
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        handleData(data["buy_rate"]);
      });
  }, []);

  let dollarPrice = 1;
  let lbpPrice = 250000;

  function handleData() {
    data["buy_rate"] = data["buy_rate"].split(",").join("");
  }
  handleData();

  function getRate() {
    fetch("https://rate.onrender.com/api/v1/dollarRate")
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        handleData();
      });
  }
  return (
    <div className="container">
      <h1 id="title">Admin</h1>
      <h1 id="sub-title">USD to LBP rate</h1>
      <div className="content">
        <div className="section">
        <section id="admin_panel">
          <div className="container admin_panel_container">
            <h3 id="main">Pricing</h3>
            <form>
              <div className="choice">
                <input
                  type="radio"
                  id="auto"
                  name="price"
                  onClick={() => {
                    setShow(false);
                    getRate();
                  }}
                />
                <label for="auto">Auto</label>
              </div>
              <div className="choice2">
                <input
                  type="radio"
                  id="manual"
                  name="price"
                  onClick={() => setShow(true)}
                />
                <label for="manual">Manual</label>
              </div>
              <div id="inp">
                {show ? <input type="number" id="rate" /> : null}
                {show ? <input type="submit" id="submit" /> : null}{" "}
                {/* <label for="manual">Manual</label> */}
              </div>
            </form>
          </div>
        </section>
        </div>
        <div className="price">
          <h2>Price in USD and LBP</h2>
          <button className="btns" onClick={getRate}>
            Get Rate
          </button>

          <button className="btns" onClick={() => setUseBlackMarketRate(true)}>
            Show Prices in Black Market Rate
          </button>
          <button className="btns" onClick={() => setUseBlackMarketRate(false)}>
            Use Lira Pricing
          </button>
          {useBlackMarketRate ? (
            <div className="append">
              <p>Price in USD = {dollarPrice}</p>
              <p>{`${dollarPrice} * ${data["buy_rate"]} = ${
                dollarPrice * parseInt(data.buy_rate)
              }`}</p>
            </div>
          ) : (
            <div className="append">
              <p>Price in LBP = {lbpPrice}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
