import React, { useState } from "react";
import { HashRouter, Route, Routes, Router } from "react-router-dom";
import "./App.css";
import EventsImages from "./components/EventsImages/EventsImages";
import Main from "./components/Main/Main";
// import Navbar from "./components/Navbar/Navbar";
import Wines from "./components/Wines/Wines";
import WinesDetails from "./components/WinesDetails/WinesDetails";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import WineRed from "./components/WineNew/WineRed";
import WineWhite from "./components/WineWhite/WineWhite";
import WineRose from "./components/WineRose/WineRose";
import WineSparkling from "./components/WineSparkling/WineSparkling";
import WineAll from "./components/WineAll/WineAll";
import "./fonts/ostrich-regular.ttf";
import Test from "./Test";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/About";
import WineList from "./components/WineList/WineList";
import Events from "./components/Events/Events";
import ContactUs from "./components/ContactUs/ContactUs";

const App = () => {
  const [number, setNumber] = useState(0);

  function fromChildToParent(num) {
    setNumber(num);
    console.log(num);
  }

  return (
    <>
      {/* <Routes>
        <Route
          path="/"
          component={Main}
          element={<Main function={fromChildToParent} />}
        />
      </Routes> */}
      <HashRouter>
        <Routes>
           <Route
          path="/"
          component={Main}
          element={<Main function={fromChildToParent} />}
        />
        
          <Route
            path="/wine_list"
            component={WineList}
            element={<WineList function={fromChildToParent} />}
          />
          <Route
            path="/About"
            component={About}
            element={<About function={fromChildToParent} />}
          />
          <Route
            path="/events"
            component={Events}
            element={<Events function={fromChildToParent} />}
          />
          <Route
            path="/contact_us"
            component={ContactUs}
            element={<ContactUs function={fromChildToParent} />}
          />
          <Route
            path="/eventsimg"
            component={EventsImages}
            element={<EventsImages function={fromChildToParent} />}
          />
          <Route
            path="/wines/:productType/*"
            component={Wines}
            element={<Wines filter={number} function={fromChildToParent} />}
          />
          <Route
            path="/winedetails/:id"
            component={WinesDetails}
            element={<WinesDetails function={fromChildToParent} />}
          />
          <Route
            path="/winesred/:productType/*"
            component={WineRed}
            element={<WineRed filter={number} function={fromChildToParent} />}
          />
          <Route
            path="/wineswhite/:productType/*"
            component={WineWhite}
            element={<WineWhite filter={number} function={fromChildToParent} />}
          />
          <Route
            path="/winesrose/:productType/*"
            component={WineRose}
            element={<WineRose filter={number} function={fromChildToParent} />}
          />
          <Route
            path="/winessparkling/:productType/*"
            component={WineSparkling}
            element={
              <WineSparkling filter={number} function={fromChildToParent} />
            }
          />
          <Route
            path="/winesall/:productType/*"
            component={WineAll}
            element={
              <WineAll filter={number} function={fromChildToParent} />
            }
          />
          <Route path="/adminpanel" component={AdminPanel} />
          {/* <Route path="/contact" component={Contact} /> */}
        </Routes>
      </HashRouter>
    </>
    // <div className="App">
    //     <Routes>
    //       <Route
    //         exact
    //         path="/"
    //         element={<Main function={fromChildToParent} />}
    //       />
    //       <Route path="/eventsimg" element={<EventsImages />} />
    //       {/* <Route path="/wineimg" component={<Wines />} /> */}
    //       {/*Route for Wines page that will render the filtered product based on the link clicked by the button in WineList */}

    //       {/* <Route path="/wines/:productType" component={<Wines />} /> */}
    //       <Route
    //         path="/wines/:productType/*"
    //         element={<Wines filter={number} function={fromChildToParent} />}
    //       />
    //       <Route
    //         path="/winedetails/:id"
    //         element={<WinesDetails function={fromChildToParent} />}
    //       />
    //       <Route
    //         path="/winesred/:productType/*"
    //         element={<WineRed filter={number} function={fromChildToParent} />}
    //       />
    //       <Route
    //         path="/wineswhite/:productType/*"
    //         element={
    //           <WineWhite filter={number} function={fromChildToParent} />
    //         }
    //       />
    //       <Route
    //         path="/winesrose/:productType/*"
    //         element={
    //           <WineRose filter={number} function={fromChildToParent} />
    //         }
    //       />
    //       <Route
    //         path="/winessparkling/:productType/*"
    //         element={
    //           <WineSparkling filter={number} function={fromChildToParent} />
    //         }
    //       />
    //       <Route path="/adminpanel" element={<AdminPanel />} />
    //     </Routes>
    //   {/* </Router> */}
    //   {/* </HashRouter> */}
    // </div>
  );
};

export default App;

// export default class App extends React.Component {
//   render() {
//     // eslint-disable-next-line react-hooks/rules-of-hooks
//     const [number, setNumber] = useState(0);

//     function fromChildToParent(num) {
//       setNumber(num);
//       console.log(num);
//     }
//     return (
//       <div className="App">
//         <Routes>
//           <Route
//             exact
//             path="/"
//             element={<Main function={fromChildToParent}/>}
//           />
//           <Route path="/eventsimg" element={<EventsImages />} />
//           {/* <Route path="/wineimg" component={<Wines />} /> */}
//           {/*Route for Wines page that will render the filtered product based on the link clicked by the button in WineList */}

//           {/* <Route path="/wines/:productType" component={<Wines />} /> */}
//           <Route
//             path="/wines/:productType/*"
//             element={<Wines filter={number} function={fromChildToParent}/>}
//           />
//           <Route
//             path="/winedetails/:id"
//             element={<WinesDetails function={fromChildToParent}/>}
//           />
//           <Route
//             path="/winesred/:productType/*"
//             element={<WineRed filter={number} function={fromChildToParent}/>}
//           />
//           <Route
//             path="/wineswhite/:productType/*"
//             element={
//               <WineWhite filter={number} function={fromChildToParent}/>
//             }
//           />
//           <Route
//             path="/winesrose/:productType/*"
//             element={
//               <WineRose filter={number} function={fromChildToParent}/>
//             }
//           />
//           <Route
//             path="/winessparkling/:productType/*"
//             element={
//               <WineSparkling filter={number} function={fromChildToParent}/>
//             }
//           />
//           <Route path="/adminpanel" element={<AdminPanel />} />
//         </Routes>
//       </div>
//     );
//   }
// }
