import React, { useRef } from "react";
import "./Welcome.css";
import Logo from "../../assets/imgs/title.png";
import ScrollLogo from "../../assets/imgs/2.png";
import SwipeLogo from "../../images/imgs-icons/scrollmobile.png";
import topLogo from "../../assets/imgs/3.png";

window.onload = function () {
  document.getElementById("welcome").selectedIndex = 1;
};

const Welcome = () => {
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section id="welcome" selectedIndex>
      <div className="topLogo">
        <img
          src={topLogo}
          alt="topLogo"
          width={270}
          height={270}
          className=""
        />
      </div>
      <div className=" welcome_container" id="welcome_cont">
        <div>
          <img loading="lazy" src={Logo} alt="logo" className="welcome_logo" />

          <div className="welcome_text_container" id="desktop-camel">
            <div className="scroll-down">
              <div className="scroll-down-icon">
                <a href="#home">
                  <img
                    loading="lazy"
                    src={ScrollLogo}
                    className="s-d-i-s computer"
                    alt=""
                  />
                  <img
                    loading="lazy"
                    src={SwipeLogo}
                    className="s-d-i-s mobile"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="welcome_text_container" id="mobile-camel">
            <div className="scroll-down">
              <div className="scroll-down-icon">
                <a href="#home">
                  <img
                    loading="lazy"
                    src={ScrollLogo}
                    className="s-d-i-s computer"
                    alt=""
                  />
                  <img
                    loading="lazy"
                    src={SwipeLogo}
                    className="s-d-i-s mobile"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
