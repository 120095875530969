import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { FiInstagram } from "react-icons/fi";
import { CgFacebook } from "react-icons/cg";
import "bootstrap/dist/css/bootstrap.min.css";
import data from "../../local-json/data.json";
import "../../fonts/ostrich-regular.ttf";
import { animateScroll as scroll } from "react-scroll";

function Navbar(props) {
  const showDropdown = () => {
    const dropdownContent = document.getElementById("dropdown-content");
    const arrowDown = document.getElementById("arrow-down");
    const arrowUp = document.getElementById("arrow-up");
    if (dropdownContent.style.display === "flex") {
      dropdownContent.style.display = "none";
      arrowDown.style.display = "block";
      arrowUp.style.display = "none";
    } else {
      dropdownContent.style.display = "flex";
      arrowUp.style.display = "block";
      arrowDown.style.display = "none";
    }
  };

  const [info, setInfo] = useState({
    checkedValues: [],
  });

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const { checkedValues } = info;

    console.log(`${value} is ${checked}`);

    if (checked) {
      setInfo({
        checkedValues: [...checkedValues, value],
      });
    } else {
      setInfo({
        checkedValues: checkedValues.filter((e) => e !== value),
      });
    }
  };

  const filter = () => {
    console.log("info: ", info);
    const filteredData = data.filter((item) => {
      return (
        //check if number of checked filters is 3
        (info.checkedValues.length === 3 &&
          info.checkedValues.includes(item.item.productType) &&
          info.checkedValues.includes(item.item.productCountry) &&
          info.checkedValues.includes(item.item.productRegion)) ||
        // //check if number of checked filters is 2
        (info.checkedValues.length === 2 &&
          ((info.checkedValues.includes(item.item.productType) &&
            info.checkedValues.includes(item.item.productCountry)) ||
            (info.checkedValues.includes(item.item.productType) &&
              info.checkedValues.includes(item.item.productRegion)) ||
            (info.checkedValues.includes(item.item.productCountry) &&
              info.checkedValues.includes(item.item.productRegion)))) ||
        // //check if number of checked filters is 1
        (info.checkedValues.length === 1 &&
          (info.checkedValues.includes(item.item.productType) ||
            info.checkedValues.includes(item.item.productCountry) ||
            info.checkedValues.includes(item.item.productRegion))) ||
        //check if no filters are checked
        info.checkedValues.length === 0 ||
        //if 1 product type is selected and multple countries and regions are selected
        (info.checkedValues.length > 1 &&
          info.checkedValues.includes(item.item.productType) &&
          (info.checkedValues.includes(item.item.productCountry) ||
            info.checkedValues.includes(item.item.productRegion))) ||
        //if 1 product country is selected and multple types and regions are selected
        (info.checkedValues.length > 1 &&
          info.checkedValues.includes(item.item.productCountry) &&
          (info.checkedValues.includes(item.item.productType) ||
            info.checkedValues.includes(item.item.productRegion))) ||
        //if 1 product region is selected and multple types and countries are selected
        (info.checkedValues.length > 1 &&
          info.checkedValues.includes(item.item.productRegion) &&
          (info.checkedValues.includes(item.item.productType) ||
            info.checkedValues.includes(item.item.productCountry)))
      );
    });
    // const filteredData = data.filter((item) => {
    //   return info.checkedValues.includes(item.item.productType);
    // }
    console.log("filteredData: ", filteredData);
    props.function(filteredData);
  };

  //Search function
  const [searchInput, setSearchInput] = useState("");

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    console.log("searchInput: ", searchInput);
  };

  const filterSearch = () => {
    const filteredData = data.filter((item) => {
      let productName = item.item.productName.toLowerCase();
      let productType = item.item.productType.toLowerCase();
      let productCountry = item.item.productCountry.toLowerCase();
      let productRegion = item.item.productRegion.toLowerCase();
      return (
        productName.includes(searchInput.toLowerCase()) ||
        productType.includes(searchInput.toLowerCase()) ||
        productCountry.includes(searchInput.toLowerCase()) ||
        productRegion.includes(searchInput.toLowerCase())
      );
    });
    console.log("filteredData: ", filteredData);
    //set the state of the filtered data to the parent component but not as a function
    props.function(filteredData);
  };

  const NavItem = ({ text, target }) => {
    const handleClick = () => {
      // Select the section element using the target
      const section = document.getElementById(target);

      // Scroll to the section
      section.scrollIntoView({ behavior: "smooth" });
    };

    // return <li onClick={handleClick}>{text}</li>;
    return (
      <a onClick={handleClick} className="nav-item">
        {text}
      </a>
    );
  };

  const scrollWineList = () => {
    // Select the section element using the target
    const section = document.getElementById("wine_list");

    // Scroll to the section
    section.scrollIntoView({ behavior: "smooth" });
  };

  const scrollHome = () => {
    // Select the section element using the target
    const section = document.getElementById("home");

    // Scroll to the section
    section.scrollIntoView({ behavior: "smooth" });
  };

  const scrollAbout = () => {
    // Select the section element using the target
    const section = document.getElementById("About");

    // Scroll to the section
    section.scrollIntoView({ behavior: "smooth" });
  };

  const scrollEvents = () => {
    // Select the section element using the target
    const section = document.getElementById("events");

    // Scroll to the section
    section.scrollIntoView({ behavior: "smooth" });
  };

  const scrollContact = () => {
    // Select the section element using the target
    const section = document.getElementById("contact_us");

    // Scroll to the section
    section.scrollIntoView({ behavior: "smooth" });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Perform the desired action here
      filterSearch();
      window.location.href = "/#/wines/SearchResults";
    }
  };

  return (
    <nav className="navbar-main">
      <div className="container-up">
        <ul className="nav-items">
          <li>
            <a onClick={scrollHome} className="nav-item">
              Home
            </a>
            {/* <NavItem text="Home" target="home" className="nav-item" /> */}
          </li>
          <li>
            <a onClick={scrollAbout} className="nav-item">
              About Us
            </a>
          </li>
          <li className="win">
            <div className="drop">
              <div className="name">
                <a onClick={scrollWineList} className="nav-item">
                  Wine List
                </a>
              </div>
              <div className="arrows" id="arrow-adj">
                <button className="arrow-button" onClick={showDropdown}>
                  <IoIosArrowDown id="arrow-down" />
                  <IoIosArrowUp id="arrow-up" />
                </button>
              </div>
            </div>
            <div className="dropdown-positions">
              <div className="dropdown-container">
                <div id="dropdown-content">
                  <div className="contents sss">
                    <div className="left-content1 lffft">
                      <div className="left-content-title">
                        <h3>Type</h3>
                      </div>
                      <div className="left-content-items">
                        <div className="checkbox">
                          <div>
                            <input
                              type="checkbox"
                              id="red"
                              value="Red"
                              onChange={handleChange}
                            />
                            <label for="red">Red</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="white"
                              value="White"
                              onChange={handleChange}
                            />
                            <label for="white">White</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="rose"
                              value="Rose"
                              onChange={handleChange}
                            />
                            <label for="rose">Rosé</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="sparkling"
                              value="Sparkling"
                              onChange={handleChange}
                            />
                            <label for="sparkling">Sparkling</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="middle-content2">
                      <div className="middle-content-title mdddle">
                        <h3>Region</h3>
                      </div>
                      <div className="middle-content-items">
                        <div className="checkbox">
                          <div>
                            <input
                              type="checkbox"
                              id="tuscany"
                              value="Tuscany"
                              onChange={handleChange}
                            />
                            <label for="tuscany">Tuscany</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="piedmont"
                              value="Piedmont"
                              onChange={handleChange}
                            />
                            <label for="piedmont">Piedmont</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="veneto"
                              value="Veneto"
                              onChange={handleChange}
                            />
                            <label for="veneto">Veneto</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right-content2 rtcnt">
                      <div className="right-content-title">
                        <h3>Country</h3>
                      </div>
                      <div className="right-content-items">
                        <div className="checkbox">
                          <div>
                            <input
                              type="checkbox"
                              id="france"
                              value="France"
                              onChange={handleChange}
                            />
                            <label for="france">France</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="italy"
                              value="Italy"
                              onChange={handleChange}
                            />
                            <label for="italy">Italy</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter btb bbs">
                    <Link to="/wines/SearchResults">
                      <button className="filter-button" onClick={filter}>
                        Filter
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li id="nav-item-events">
            <a onClick={scrollEvents} className="nav-item">
              News
            </a>
          </li>
          <li id="nav-item-contact">
            <a onClick={scrollContact} className="nav-item">
              Contact Us
            </a>
          </li>
        </ul>
        <div className="nav-right">
          <div className="social-icons">
            <div>
              <a href="https://www.facebook.com/profile.php?id=100088972295767">
                <CgFacebook className="facebook-icon" />
              </a>
            </div>
            <div>
              {/* <a href="https://www.instagram.com/thewinehaven.shop/">
                <FiInstagram className="instagram-icon" />
              </a> */}
            </div>
          </div>
          <div className="search" onKeyDown={handleKeyDown}>
            <input
              type="text"
              placeholder="Search Product..."
              onFocus={(e) => (e.target.placeholder = "Type")}
              onBlur={(e) => (e.target.placeholder = "Search Product...")}
              value={searchInput}
              onChange={handleChangeSearch}
            />
            <Link to="/wines/SearchResults">
              <button className="search-button" onClick={filterSearch}>
                <CiSearch className="search-icon" />
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="container-down"></div>
    </nav>
  );
}

export default Navbar;
