import React from "react";
import "./Home.css";
import Navbar from "../Navbar/Navbar";

const Home = (props) => {
  return (
    <section id="home">
      <div className="nav-section">
        <Navbar function={props.function} />
      </div>
      <div className="home_container">
      </div>
      <div className="text-home">
        <h1>Your Wine Partner For Success</h1>
        {/* <p></p> */}
        {/* <h1>YOUR HOMES</h1> */}
      </div>
    </section>
  );
};

export default Home;
