import React from "react";
import "./About.css";
import lion from "../../assets/imgs/11.png";
import topLogo from "../../assets/imgs/3.png";

const About = () => {
  return (
    <section id="About">
      <div className="topLogo">
        <img
          src={topLogo}
          alt="topLogo"
          width={235}
          height={235}
          className=""
        />
      </div>
      <div className=" about_container">
        <div className="logo_about">
          <img loading="lazy" alt="logo" width={700} height={120} />
        </div>
        <div className="about_text">
          <div className="about_top">
            <div className="about_title_img">
              <img
                loading="lazy"
                src={lion}
                alt="logo"
                width={140}
                height={140}
              />
            </div>
            <div className="about_title_div">
              <h1 className="about_title">
                <span className="bigLetter">A</span>BOUT US
              </h1>
            </div>
          </div>
          {/* <hr /> */}
          {/* <br /> */}
          <p>
            As a top wine import and distribution company, Saluti Trading serves
            major F&B clients with expertly sourced exceptional wines from
            around the world. Our selection, customer service, and deep
            knowledge of the industry are what set us apart from the rest of the
            market. Let us be your trusted partner in elevating your wine
            offerings and driving business growth.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
