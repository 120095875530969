import React from "react";
import "./Events.css";
import { AiFillRightCircle } from "react-icons/ai";
import { AiFillLeftCircle } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import exhibitions1 from "../../images/events/exhibitions1.jpg";
import exhibitions2 from "../../images/events/exhibitions2.jpg";
import exhibitions3 from "../../images/events/exhibitions3.jpg";
import exhibitions4 from "../../images/events/exhibitions4.jpg";
import exhibitions5 from "../../images/events/exhibitions5.jpg";
import exhibitions6 from "../../images/events/exhibitions6.jpg";
import exhibitions7 from "../../images/events/exhibitions7.jpg";

import wineries1 from "../../images/events/wineries1.png";
import wineries2 from "../../images/events/wineries2.png";
import wineries3 from "../../images/events/wineries3.jpg";
import wineries4 from "../../images/events/wineries4.png";
import wineries5 from "../../images/events/wineries5.png";
import wineries6 from "../../images/events/wineries6.png";
import wineries7 from "../../images/events/wineries7.jpg";
import wineries8 from "../../images/events/wineries8.jpg";
import wineries9 from "../../images/events/wineries9.png";
import wineries10 from "../../images/events/wineries10.jpg";
import wineries11 from "../../images/events/wineries11.png";
import wineries12 from "../../images/events/wineries12.png";
import wineries13 from "../../images/events/wineries13.jpg";
import wineries14 from "../../images/events/wineries14.png";
import wineries15 from "../../images/events/wineries15.png";
import wineries16 from "../../images/events/wineries16.jpg";
import wineries17 from "../../images/events/wineries17.png";

import c1 from "../../images/events/c1.png";
import c2 from "../../images/events/c2.png";
import c3 from "../../images/events/c3.png";
import c4 from "../../images/events/c4.png";
import c5 from "../../images/events/c5.png";
import c6 from "../../images/events/c6.png";
import c7 from "../../images/events/c7.png";
import c8 from "../../images/events/c8.png";
import c9 from "../../images/events/c9.png";
import c10 from "../../images/events/c10.png";
import c11 from "../../images/events/c11.png";
import c12 from "../../images/events/c12.png";
import c13 from "../../images/events/c13.png";
import c14 from "../../images/events/c14.png";
import c15 from "../../images/events/c15.png";
import c16 from "../../images/events/c16.png";
import c17 from "../../images/events/c17.png";
import c18 from "../../images/events/c18.png";

import sommelier from "../../images/sommelier.jpg";
import gatherings from "../../images/gatherings.jpg";
import venue from "../../images/exhibitions1.jpg";

import lion from "../../assets/imgs/11.png";
import topLogo from "../../assets/imgs/3.png";

const Events = () => {
  var fbox = document.getElementById("fullimgbox");
  var fullimg = document.getElementById("fullimg");
  var caption = document.getElementById("caption");
  var socials = document.getElementById("sokonawnaw");
  var backhome = document.getElementById("sokohawhaw");

  const Images1 = [
    exhibitions1,
    exhibitions2,
    exhibitions3,
    exhibitions4,
    exhibitions5,
    exhibitions6,
    exhibitions7,
  ];

  const Images2 = [
    wineries1,
    wineries2,
    wineries3,
    wineries4,
    wineries5,
    wineries6,
    wineries7,
    wineries8,
    wineries9,
    wineries10,
    wineries11,
    wineries12,
    wineries13,
    wineries14,
    wineries15,
    wineries16,
    wineries17,
  ];

  const Images3 = [
    exhibitions1,
    exhibitions2,
    exhibitions3,
    exhibitions4,
    exhibitions5,
    exhibitions6,
    exhibitions7,
  ];

  const Images4 = [
    c1,
    c2,
    c3,
    c4,
    c5,
    c6,
    c7,
    c8,
    c9,
    c10,
    c11,
    c12,
    c13,
    c14,
    c15,
    c16,
    c17,
    c18,
  ];

  const Captions1 = [
    "January '23 Artist - Mohammad El Dreini                                ",
    "January '23 Artist - Mohammad El Dreini                                ",
    "January '23 Artist - Mohammad El Dreini                                ",
    "January '23 Artist - Mohammad El Dreini                               ",
  ];
  const Captions2 = [
    "BEAUCHATEL",
    "BERGERAC",
    "Chateau Marquis de Terme",
    "Chateau Luduiraut",
    "Chateau Tauzinat",
    "Chateau Clos de Prince",
    "GP Bordaux",
    "Chateau Jean L'Arc",
    "Mangot Todeschini",
    "Clos des Lunes",
    "Chateau Cussseau",
    "Yvon Mau",
    "Chateau La Fluer Du Casse",
    "Chateau Haut Bernicot",
    "French Dog",
    "Premius",
    "Chateau Du Val D'or",
    "ICASTELLI",
  ];
  const Captions3 = [
    "Vinifest 2017",
    "Vinifest 2017",
    "Vinifest 2017",
    "Vinifest 2018",
    "Vinifest 2018",
    "Vinifest 2018",
    "Vinifest 2018",
  ];
  const Captions4 = [
    `Amelia. <a className="atag" href="https://www.instagram.com/ameliabeirut/">   Check them out!</a>`,
    `Art Haus. <a className="atag" href="https://www.instagram.com/arthausbeirut/">   Check them out!</a>`,
    `Central Station. <a className="atag" href="https://www.instagram.com/central.station/">   Check them out!</a>`,
    `Craft Boutik. <a className="atag" href="https://www.instagram.com/craftboutik/">   Check them out!</a>`,
    `Ginger & Co. <a className="atag" href="https://www.instagram.com/gingernco/">   Check them out!</a>`,
    `Garcias. <a className="atag" href="https://www.instagram.com/garciasleb/">   Check them out!</a>`,
    `Kellys Fish Lounge. <a className="atag" href="https://www.instagram.com/kellysfishlounge/">   Check them out!</a>`,
    `Luigi. <a className="atag" href="https://www.instagram.com/luigicucinaitaliana/">   Check them out!</a>`,
    `Miss pepper. <a className="atag" href="https://www.instagram.com/misspepper3/">   Check them out!</a>`,
    `Pazzi. <a className="atag" href="https://www.instagram.com/pazzi.lb/">   Check them out!</a>`,
    `Per tutti. <a className="atag" href="https://www.instagram.com/pertuttilb/">   Check them out!</a>`,
    `Prune. <a className="atag" href="https://www.instagram.com/prunebeirut/">   Check them out!</a>`,
    `Rikkyz. <a className="atag" href="https://www.instagram.com/rikkyz_/">   Check them out!</a>`,
    `Slate Bistro Grill. <a className="atag" href="https://www.instagram.com/slatebistrogrill/">   Check them out!</a>`,
    `Sporting club. <a className="atag" href="https://www.instagram.com/sportingclubbeach/">   Check them out!</a>`,
    `Tavolina. <a className="atag" href="https://www.instagram.com/tavolinatrattoria/">   Check them out!</a>`,
    `Smallville Hotel. <a className="atag" href="https://www.instagram.com/thesmallvillehotel/">   Check them out!</a>`,
    `Vinoteque. <a className="atag" href="https://www.instagram.com/vinotheque.lebanon/">   Check them out!</a>`,
  ];

  function closeimg() {
    fbox.style.display = "none";
    socials.style.display = "flex";
    backhome.style.display = "grid";
  }

  let currentArray = Images1;
  let currentCaptions = Captions1;
  let currentIndex = 0;

  function openfullimg1() {
    currentArray = Images1;
    currentCaptions = Captions1;
    currentIndex = 0;
    fullimg.src = Images1[currentIndex];
    // target caption  innerhtml to write code in the p tag in the html
    caption.innerHTML = Captions1[currentIndex];
    // caption.innerHTML = Captions1[currentIndex];
    fbox.style.display = "flex";
    socials.style.display = "none";
    backhome.style.display = "none";
  }

  function openfullimg2() {
    currentArray = Images2;
    currentCaptions = Captions2;
    currentIndex = 0;
    fullimg.src = Images2[currentIndex];
    caption.innerHTML = Captions2[currentIndex];
    fbox.style.display = "flex";
    socials.style.display = "none";
    backhome.style.display = "none";
  }

  function openfullimg3() {
    currentArray = Images3;
    currentCaptions = Captions3;
    currentIndex = 0;
    fullimg.src = Images3[currentIndex];
    caption.innerHTML = Captions3[currentIndex];
    fbox.style.display = "flex";
    socials.style.display = "none";
    backhome.style.display = "none";
  }

  function openfullimg4() {
    currentArray = Images4;
    currentCaptions = Captions4;
    currentIndex = 0;
    fullimg.src = Images4[currentIndex];
    caption.innerHTML = Captions4[currentIndex];
    fbox.style.display = "flex";
    socials.style.display = "none";
    backhome.style.display = "none";
  }

  function moveRight() {
    currentIndex++;
    if (currentIndex >= currentArray.length) {
      currentIndex = 0;
    }
    fullimg.src = currentArray[currentIndex];
    caption.innerHTML = currentCaptions[currentIndex];
  }

  function moveLeft() {
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = currentArray.length - 1;
    }
    fullimg.src = currentArray[currentIndex];
    caption.innerHTML = currentCaptions[currentIndex];
  }

  //when user press right arrow key on keyboard it will move to the next image
  document.addEventListener("keydown", function (e) {
    if (e.key === "ArrowRight") {
      moveRight();
    }
  });

  //when user press left arrow key on keyboard it will move to the previous image
  document.addEventListener("keydown", function (e) {
    if (e.key === "ArrowLeft") {
      moveLeft();
    }
  });

  return (
    <section id="events">
      <div className="topLogo">
        <img
          src={topLogo}
          alt="topLogo"
          width={235}
          height={235}
          className=""
        />
      </div>
      <div className="logo_events">
        <img loading="lazy" alt="logo" width={700} height={120} />
      </div>
      <div className="events_container">
        <div className="events_text">
          <div className="events_top">
            <div className="events_title_img">
              <img
                loading="lazy"
                src={lion}
                alt="logo"
                width={140}
                height={140}
              />
            </div>
            <div className="events_title_div">
              <h1 className="events_title">
                <span className="bigLetter">N</span>EWS
              </h1>
            </div>
          </div>
          {/* <hr /> */}
          <p>
            Stay informed on Saluti Trading's latest news, including updates on
            our wineries, upcoming exhibitions, and where to find our selection
            of bottles.
          </p>
        </div>
        <div className="event_container_below">
          <div className="full-img" id="fullimgbox">
            <AiFillLeftCircle className="left-arrow" onClick={moveLeft} />
            <figure>
              <img loading="lazy" alt="image" id="fullimg" src="" />
              <div className="caption">
                <p id="caption"></p>
              </div>
            </figure>
            <AiFillRightCircle className="right-arrow" onClick={moveRight} />
            <span>
              <AiFillCloseCircle className="close" onClick={closeimg} />
            </span>
          </div>
          <div className="events_images">
            <div onClick={openfullimg2}>
              <div className="event_image event-1">
                <div
                  loading="lazy"
                  // src={tasting}
                  alt="image"
                  onClick={openfullimg2}
                  width={240}
                  height={240}
                ></div>
                <div onClick={openfullimg2} className="events_image_titleeee">
                  {/* ARTISTS */}
                </div>
              </div>
            </div>
            <div onClick={openfullimg2}>
              <div className="event_image_right" id="edit_box">
                <img
                  loading="lazy"
                  src={gatherings}
                  alt="image"
                  onClick={openfullimg2}
                  width={240}
                  height={240}
                />
                <button
                  onClick={openfullimg2}
                  className="events_image_title_right"
                >
                  WINERIES
                </button>
              </div>
            </div>
            <div onClick={openfullimg3}>
              <div className="event_image event_image_below event-2">
                <img
                  loading="lazy"
                  src={venue}
                  alt="image"
                  onClick={openfullimg3}
                  width={240}
                  height={240}
                />
                <button
                  onClick={openfullimg3}
                  className="events_image_title event_image_title_left_down"
                >
                  EXHIBITIONS
                </button>
              </div>
            </div>
            <div onClick={openfullimg4}>
              <div className="event_image_right event_image_below">
                <img
                  loading="lazy"
                  src={sommelier}
                  alt="image"
                  onClick={openfullimg4}
                  width={240}
                  height={240}
                />
                <button
                  onClick={openfullimg4}
                  className="events_image_title_right_down points_of_sale"
                >
                  OUR CLIENTS
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Events;
