import React, { useState } from "react";
import "./WineList.css";
import { Link } from "react-router-dom";
import "../../App.css";
import data from "../../local-json/data.json";
import { useEffect } from "react";
import lion from "../../assets/imgs/11.png";
import topLogo from "../../assets/imgs/3.png";

const WineList = (props) => {
  window.onbeforeunload = function (e) {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    // refresh the page here
  }, []);

  const list = data;
  const handleClick = (e) => {
    const productType = e.target.value;
    console.log(productType);
    const filteredList = list.filter(
      (item) => item.item.productType === productType
    );

    console.log(filteredList);
    props.function(filteredList);
  };

  const handlehome = () => {
    window.location.href = "/#home";
  };

  const [info, setInfo] = useState({
    checkedValues: [],
  });

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const { checkedValues } = info;

    console.log(`${value} is ${checked}`);

    if (checked) {
      setInfo({
        checkedValues: [...checkedValues, value],
      });
    } else {
      setInfo({
        checkedValues: checkedValues.filter((e) => e !== value),
      });
    }
  };

  const filter = () => {
    console.log("info: ", info);
    const filteredData = data.filter((item) => {
      return (
        //check if number of checked filters is 3
        (info.checkedValues.length === 3 &&
          info.checkedValues.includes(item.item.productType) &&
          info.checkedValues.includes(item.item.productCountry) &&
          info.checkedValues.includes(item.item.productRegion)) ||
        // //check if number of checked filters is 2
        (info.checkedValues.length === 2 &&
          ((info.checkedValues.includes(item.item.productType) &&
            info.checkedValues.includes(item.item.productCountry)) ||
            (info.checkedValues.includes(item.item.productType) &&
              info.checkedValues.includes(item.item.productRegion)) ||
            (info.checkedValues.includes(item.item.productCountry) &&
              info.checkedValues.includes(item.item.productRegion)))) ||
        // //check if number of checked filters is 1
        (info.checkedValues.length === 1 &&
          (info.checkedValues.includes(item.item.productType) ||
            info.checkedValues.includes(item.item.productCountry) ||
            info.checkedValues.includes(item.item.productRegion))) ||
        //check if no filters are checked
        info.checkedValues.length === 0 ||
        //if 1 product type is selected and multple countries and regions are selected
        (info.checkedValues.length > 1 &&
          info.checkedValues.includes(item.item.productType) &&
          (info.checkedValues.includes(item.item.productCountry) ||
            info.checkedValues.includes(item.item.productRegion))) ||
        //if 1 product country is selected and multple types and regions are selected
        (info.checkedValues.length > 1 &&
          info.checkedValues.includes(item.item.productCountry) &&
          (info.checkedValues.includes(item.item.productType) ||
            info.checkedValues.includes(item.item.productRegion))) ||
        //if 1 product region is selected and multple types and countries are selected
        (info.checkedValues.length > 1 &&
          info.checkedValues.includes(item.item.productRegion) &&
          (info.checkedValues.includes(item.item.productType) ||
            info.checkedValues.includes(item.item.productCountry)))
      );
    });
    // const filteredData = data.filter((item) => {
    //   return info.checkedValues.includes(item.item.productType);
    // }
    console.log("filteredData: ", filteredData);
    props.function(filteredData);
  };

  return (
    <section id="wine_list">
      <div className="topLogo-list">
        <img
          src={topLogo}
          alt="topLogo"
          width={235}
          height={235}
          className=""
        />
      </div>
      <div className="wine_container">
        <div className="logo_wine_list">
          <img loading="lazy" alt="logo" width={700} height={120} />
        </div>
        <div className="content">
          <div className="wine-cat">
            {/* 4 buttons for wine categories */}
            <div className="btns">
              <div className="btn-2">
                <Link to="/winesred/SearchResults">
                  <button
                    value="Red"
                    className="wine-cat-btn"
                    onClick={handleClick}
                  >
                    Red
                  </button>
                </Link>
                <Link to="/wineswhite/SearchResults">
                  <button
                    value="White"
                    className="wine-cat-btn"
                    onClick={handleClick}
                  >
                    White
                  </button>
                </Link>
              </div>
              <div className="btn-2">
                <Link to="/winesrose/SearchResults">
                  <button
                    value="Rose"
                    className="wine-cat-btn"
                    onClick={handleClick}
                  >
                    Rose
                  </button>
                </Link>
                <Link to="/winessparkling/SearchResults">
                  <button
                    value="Sparkling"
                    className="wine-cat-btn"
                    onClick={handleClick}
                  >
                    Sparkling
                  </button>
                </Link>
              </div>
            </div>
            <div className="all">
              <Link to="/winesall/SearchResults">
                <button
                  value={"All"}
                  className="wine-cat-btn-all"
                  onClick={filter}
                >
                  View All
                </button>
              </Link>
            </div>
          </div>
          <div className="wine_text">
            <div className="wine_top">
              <div className="wine_title_img">
                <img
                  loading="lazy"
                  src={lion}
                  alt="logo"
                  width={140}
                  height={140}
                />
              </div>
              <div className="wine_title_div">
                <h1 className="wine_title">
                  <span className="bigLetter">W</span>INE LIST
                </h1>
              </div>
            </div>
            {/* <hr /> */}
            <p>
              Our wine selection features the finest vintages from top wineries
              worldwide, with a focus on French and Italian wineries. Elevate
              your offerings with our expertly curated selection that will cater
              to any of your customers' needs.
            </p>
          </div>
          {/* <div className="wine_text2">
            <h1 className="wine_title"><span className="bigLetter">W</span>INE LIST</h1>
            <p>A sip of aged wine, a taste of the recent,</p>
            <p>Each bottle a tale, ready to unfold.</p>
            <p>A bouquet of oak, a hint of fruit,</p>
            <p>Our wine cellar, a true bounty to hold.</p>
          </div> */}
        </div>
      </div>
    </section>
  );
  // });
};

export default WineList;
